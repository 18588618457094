<template>
  <div
    class="h-screen flex flex-col"
    v-if="project && materials && operations && printers"
  >
    <div class="flex-1 flex min-h-0">
      <div class="flex-1 overflow-hidden relative">
        <div
          class="w-full h-full"
          ref="cy"
          v-mount="mountGraph"
          v-unmount="unmountGraph"
        />
      </div>
    </div>
    <!-- dialogs -->
  </div>
</template>

<script>
import {
  taskInstanceBase,
  getComponent,
  evaluateInContext,
  baseComponentRequest,
  normalizeComponentRequest
} from "@/calculator";
import cuid from "cuid";
import { keyBy } from "lodash";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";
import { graphStyle, getProjectGraphElements } from "@/utils/graph";

cytoscape.use(dagre);

export default {
  props: ["id", "print"],
  data() {
    return {
      advanced: true,
      selectedElement: null,
      connectMode: false,
      printers: null,
      operations: null,
      materials: null,
      materialCategories: null,
      project: null,
      projectData: null,
      saving: false
    };
  },
  computed: {
    printersById() {
      return keyBy(this.printers, "id");
    },
    operationsById() {
      return keyBy(this.operations, "id");
    },
    materialsById() {
      return keyBy(this.materials, "id");
    },
    largeSheets() {
      if (this.materials === null) return null;
      return this.materials.filter(
        ({ data }) => data.weight && data.width && data.height
      );
    },
    context() {
      return this.project.variables;
    },
    database() {
      return {
        printersById: this.printersById,
        operationsById: this.operationsById,
        materialsById: this.materialsById
      };
    }
  },
  created() {
    this.initGraph();
    this.fetchProject(this.id);
    this.$bind("printers", this.$firestore().collection("printers"));
    this.$bind("operations", this.$firestore().collection("operations"));
    this.$bind("materials", this.$firestore().collection("materials"));
    this.$bind(
      "materialCategories",
      this.$firestore().collection("materialCategories")
    );
  },
  watch: {
    projectData: {
      deep: true,
      handler() {
        const elements = getProjectGraphElements(this.projectData);
        this.cy.json({ elements });
      }
    }
  },
  methods: {
    initGraph() {
      let obj = {
        elements: [],
        minZoom: 0.5,
        maxZoom: 1,
        boxSelectionEnabled: false,
        selectionType: "single",
        style: graphStyle
      };
      if (this.print) {
        (obj.zoomingEnabled = false),
          (obj.zoom = 0.5),
          (obj.pan = { x: 150, y: 50 });
      }
      this.cy = cytoscape(obj);

      this.cy.on("select", this.onSelectElement);
      this.cy.on("unselect", this.onUnselectElement);
    },
    mountGraph(el) {
      this.cy.mount(el);
      this.refresh();
    },
    unmountGraph() {
      this.cy.unmount();
    },
    async fetchProject(id) {
      const projectSnapshot = await this.$fetch(
        this.$firestore().doc(`projects/${id}`)
      );
      this.project = projectSnapshot.data;
      this.projectData = projectSnapshot.data;

      // if (Object.values(this.projectData.tasks).length === 0) {
      //   this.$nextTick(this.openTemplatesDialog);
      // }
    },
    async saveProject() {
      try {
        this.saving = true;
        // this.syncGraphToData();
        await this.$firestore()
          .doc(`projects/${this.id}`)
          .update({
            ...this.projectData,
            updatedAt: this.$firestore.FieldValue.serverTimestamp()
          });
        this.$message.success("Modificarea a fost efectuata.");
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.");
      } finally {
        this.saving = false;
      }
    },
    refresh() {
      this.createHiddenEdges();
      this.runLayout();
    },
    runLayout() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.cy
            .layout({
              name: "dagre",
              nodeDimensionsIncludeLabels: true
            })
            .run();
        });
      });
    },
    selectElement(id) {
      if (this.selectedElement) {
        this.cy.getElementById(this.selectedElement.id).unselect();
      }
      this.$nextTick(() => this.cy.getElementById(id).select());
    },
    onSelectElement(event) {
      const id = event.target.id();
      const type = event.target.data("type");

      if (type === "task") {
        this.selectedElement = this.projectData.tasks[id];
      } else if (type === "component") {
        this.selectedElement = this.projectData.components[id];
      } else {
        this.selectedElement = this.projectData.edges[id];
      }
    },
    onUnselectElement() {
      this.selectedElement = null;
    },
    removeSelectedElement() {
      this.removeElement(this.selectedElement);
      this.selectedElement = null;
    },
    removeElement(element) {
      const { id, type } = element;
      if (type === "task") {
        this.$delete(this.projectData.tasks, id);
        this.removeConnectedEdges(id);
      } else if (type === "component") {
        this.$delete(this.projectData.components, id);
        this.removeConnectedEdges(id);
        this.removeChildrenNodes(id);
      } else {
        this.$delete(this.projectData.edges, id);
      }
    },
    removeConnectedEdges(id) {
      this.cy
        .getElementById(id)
        .connectedEdges()
        .forEach(edge => {
          this.$delete(this.projectData.edges, edge.id());
        });
    },
    removeChildrenNodes(id) {
      this.cy
        .getElementById(id)
        .children()
        .forEach(node => {
          this.removeConnectedEdges(node.id());
          this.$delete(this.projectData.tasks, node.id());
        });
    },
    connectSelectedElement() {
      this.cy.autounselectify(true);
      this.connectMode = true;

      this.cy.once("tap", event => {
        this.connectMode = false;
        if (event.target === this.cy) return;
        if (!event.target.isNode()) return;
        if (event.target.isChild()) return;

        const source = this.selectedElement.id;
        const target = event.target.id();

        if (source === target) return;

        const id = cuid();
        this.$set(this.projectData.edges, id, {
          id,
          source,
          target
        });
        this.refresh();
        this.selectElement(target);

        this.cy.autounselectify(false);
      });
    },
    connectManyToMany(sources, targets, extra) {
      sources.forEach(source => {
        targets.forEach(target => {
          const id = cuid();
          this.$set(this.projectData.edges, id, {
            id,
            source: source.id(),
            target: target.id(),
            ...extra
          });
        });
      });
    },
    createHiddenEdges() {
      this.cy.edges("edge[hidden]").forEach(edge => {
        this.$delete(this.projectData.edges, edge.id());
      });
      this.$nextTick(() => {
        this.cy.edges().forEach(edge => {
          const source = edge.source();
          const target = edge.target();
          const sourceIsTask = source.data("type") === "task";
          const targetIsTask = target.data("type") === "task";
          // one node must be component
          if (sourceIsTask && targetIsTask) return;
          this.connectManyToMany(
            sourceIsTask ? source : source.children().leaves(),
            targetIsTask ? target : target.children().roots(),
            { hidden: true }
          );
        });
      });
    },
    addTask() {
      const id = cuid();
      this.$set(this.projectData.tasks, id, {
        id,
        type: "task",
        name: "???",
        operations: [],
        materials: [],
        ...taskInstanceBase
      });
      this.selectElement(id);
      this.refresh();
    },
    addComponent() {
      const request = normalizeComponentRequest(
        {
          ...baseComponentRequest,
          pages: 2,
          frontColors: 4,
          backColors: 4,
          finalSheetLayouts: ["1x1"],
          sheetCategoryId: "zeIfm7sFicTJLtt1pZBD",
          sheetWeight: 130
        },
        this.printers,
        this.largeSheets
      );

      const id = cuid();
      this.$set(this.projectData.components, id, {
        id,
        type: "component",
        name: "???",
        request
      });

      this.selectElement(id);
      this.regenerateComponent(id);
    },
    regenerateComponent(componentId) {
      const componentElement = this.projectData.components[componentId];
      componentElement.request = {
        ...baseComponentRequest,
        ...componentElement.request
      };

      const { request } = componentElement;

      const { impositions, tasks } = getComponent(
        request,
        this.context,
        this.database
      );

      const nodes = tasks.map(task => ({
        id: cuid(),
        type: "task",
        parent: componentId,
        ...task,
        ...taskInstanceBase
      }));

      const edges = [];
      for (let i = 0; i < nodes.length - 1; i++) {
        edges.push({
          id: cuid(),
          source: nodes[i].id,
          target: nodes[i + 1].id
        });
      }

      this.cy
        .getElementById(componentId)
        .children()
        .forEach(task => this.removeElement(task.data()));

      this.$set(componentElement, "impositions", impositions);

      nodes.forEach(node => {
        this.$set(this.projectData.tasks, node.id, node);
      });
      edges.forEach(edge => {
        this.$set(this.projectData.edges, edge.id, edge);
      });

      this.refresh();

      if (impositions.length === 0) {
        alert("Nu am gasit nici o solutie");
      }
    },
    evaluate(expr) {
      try {
        return evaluateInContext(expr, this.context);
      } catch (error) {
        return "???";
      }
    },
    onTemplateSelect(template) {
      this.project.edges = template.edges;
      this.project.tasks = template.tasks;
      this.project.components = template.components;
      this.project.variables = template.variables;
    },
    openTemplatesDialog() {
      this.$refs.templatesDialog.open();
    },
    openPriceDialog() {
      this.$refs.priceDialog.open();
    },
    openStockStatusDialog() {
      this.$refs.stockStatusDialog.open();
    },
    openActivitiesDialog() {
      this.$refs.activitiesDialog.open();
    }
  }
};
</script>
