<template>
  <div v-if="project" class="flex flex-col h-full items-center">
    <div style="height:100vh">
      <div
        class="text-center"
        style="font-size: 2.2cm; line-height: 2.2cm; margin-top:20px;"
      >
        #{{ project.data.number }}
      </div>
      <div class="text-center" style="font-size: 0.5cm;">
        {{ project.data.name }}
      </div>
      <div class="text-center" style="font-size: 0.5cm;">
        {{ client ? client.data.name : "???" }}
      </div>
      <div class="flex-1 text-center">
        Tiraj: {{ project.data.variables.quantity }}
      </div>
      <div class="flex-1 text-center" style="white-space: pre-wrap;">
        {{ project.data.description }}
      </div>
    </div>
    <div class="w-full h-full h2">
      <project-schema-preview
        :print="true"
        :id="project.id"
      ></project-schema-preview>
    </div>
  </div>
</template>

<style>
body {
  margin: 0;
  background: white;
  -webkit-print-color-adjust: exact;
}
@page {
  size: A5 landscape;
  margin: 0;
}
</style>

<script>
import ProjectSchemaPreview from "./ProjectSchemaPreview"
import { evaluateInContext, countUniques } from "@/calculator"
import { keyBy } from "lodash"
export default {
  props: ["id"],
  data() {
    return {
      project: null,
      client: null,
      materials: null,
    }
  },
  components: {
    ProjectSchemaPreview,
  },
  async created() {
    this.$bind("materials", this.$firestore().collection("materials"))
    this.project = await this.$fetch(
      this.$firestore().doc(`projects/${this.id}`)
    )
    if (this.project.data.clientId) {
      this.client = await this.$fetch(
        this.$firestore().doc(`clients/${this.project.data.clientId}`)
      )
    }
    // this.$nextTick(() => {
    //   window.print();
    // });
  },
  computed: {
    materialsById() {
      return keyBy(this.materials, "id")
    },
    context() {
      return this.project.data.variables
    },
  },
  methods: {
    tasksByComponent(id) {
      return Object.values(this.project.data.tasks).filter(
        (x) => x.parent === id
      )
    },
    getUnique(materials) {
      return countUniques(materials)
    },
    evaluate(expr) {
      try {
        return evaluateInContext(expr, this.context)
      } catch (error) {
        return "???"
      }
    },
  },
}
</script>
